import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#E02020" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/Burnaby-Palace-Restaurant-119270129466710">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Burnaby Palace Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/burnaby-palace-restaurant-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Burnaby Palace Yelp"
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/burnaby-palace-restaurant-central-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Burnaby Palace Zomato"
              />
            </CFLink>
            {/* <CFLink href="https://www.instagram.com/saladedefruitscafe/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Burnaby Palace Instagram"
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#E02020" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/Burnaby-Palace-Restaurant-119270129466710">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Burnaby Palace Facebook"
                hover
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/burnaby-palace-restaurant-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Burnaby Palace Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/burnaby-palace-restaurant-central-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Burnaby Palace Zomato"
                hover
              />
            </CFLink>
            {/* <CFLink href="https://www.instagram.com/saladedefruitscafe/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Burnaby Palace Instagram"
                hover
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
