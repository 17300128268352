import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  mobileDownloadButton,
  mobilePickupButton,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView mb="3vh">
            <OrderPickupButton />
            <a href={'http://onelink.to/rngspp'}>
              <CFView hover>
                <CFImage src={downloadAppButton} maxWidth="310px" alt="About" />
              </CFView>
            </a>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          maxHeight="700px"
          zIndex={90}
          column
          justifyStart
          relative
        >
          <Header />
          <CFImage src={hero} w="100%" maxWidth="1400px" alt="hero" />
          <CFView row center absolute top="85%" left="50%" ml="-25%">
            <CFView pr="20px">
              <OrderPickupButton />
            </CFView>
            <CFLink href={'http://onelink.to/rngspp'} newTab>
              <CFView hover>
                <CFImage
                  src={downloadAppButton}
                  maxWidth="360px"
                  w="24vw"
                  alt="About"
                />
              </CFView>
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
